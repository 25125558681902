import React from "react";

const counterText = count => {
  if (count === 0) return "No";
  if (count > 99) return "99+";

  return `${count}`;
};

export const Counter = ({ count, label, suffix }) => {
  const labelText = `${label}${count === 1 ? "" : suffix}`;

  return (
    <span>
      <b>{counterText(count)}</b> {labelText}
    </span>
  );
};
