import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Dropdown } from "~brokerage/components/shared/Form";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";
import { getTimeOptions } from "./utils";

const TimeRange = ({ id, range, onRangeChange, onRemove }) => {
  const handleChange = changes => onRangeChange(id, changes);

  const handleRemoveClick = () => onRemove(range);

  return (
    <div>
      <Row>
        <Col>
          <Dropdown
            name="startAt"
            options={getTimeOptions("24:00", "start")}
            selected={range.startAt}
            variant="outline2"
            onChange={handleChange}
          />
        </Col>
        <Col modifier="offset10">
          <Row offset="10">to</Row>
        </Col>
        <Col modifier="offset10">
          <Dropdown
            name="endAt"
            options={getTimeOptions("00:00")}
            selected={range.endAt}
            variant="outline2"
            onChange={handleChange}
          />
        </Col>
        <Col modifier="offset10">
          <Row style={{ marginTop: "7px" }}>
            <Button variant="link" onClick={handleRemoveClick}>
              <Icon name="decline2" size="14" />
            </Button>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

TimeRange.propTypes = {
  id: PropTypes.number,
  range: PropTypes.shape({
    startAt: PropTypes.string,
    endAt: PropTypes.string
  }),
  onRangeChange: PropTypes.func,
  onRemove: PropTypes.func
};

export default TimeRange;
