import React, { useState } from "react";
import css from "./SurveyFeedback.sass";
import Button from "~brokerage/components/shared/Button";
import { useHistory, useRouteMatch } from "react-router-dom";
import SurveyFeedbackModal from "~brokerage/components/shared/SurveyFeedbackModal";

const SurveyFeedback = ({
  details: { address, feedback_count: surveyCount }
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);

  return (
    <div className={css.buttonContainer}>
      <SurveyFeedbackModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        listingAddress={address}
        isSeller={true}
      />
      <Button onClick={openModal} variant="link" className={css.button}>
        View Feedback
        <span className={css.feedbackCount}>{` (${surveyCount})`}</span>
      </Button>
    </div>
  );
};

export default SurveyFeedback;
