import {
  RiPhoneLine,
  RiMailLine,
  RiGlobalLine,
  RiSurveyLine
} from "@remixicon/react";

export const SUPPORT_DETAILS = [
  { key: "phone", label: "Phone", hrefType: "tel", icon: RiPhoneLine },
  { key: "email", label: "Email", hrefType: "mailto", icon: RiMailLine },
  {
    key: "suggestionsEmail",
    label: "Feedback and Suggestions Email",
    hrefType: "mailto",
    icon: RiMailLine
  },
  { key: "website", label: "Website", icon: RiGlobalLine },
  { key: "feedback", label: "feedback", icon: RiSurveyLine }
];
