import config from "~brokerage/config/config";
import { formatTime, inLocalTimeZoneWithoutConversion } from "./TimeHelper";
import { formatDate } from "./DateHelper";
import moment from "moment";
import { isEqual, uniqWith } from "lodash";

export const sortRestrictions = restrictions =>
  [...restrictions].sort((a, b) => {
    if (a.repetitionType === "weekly" && b.repetitionType === "one_time")
      return -1;

    if (a.repetitionType === "one_time" && b.repetitionType === "weekly")
      return 1;

    return 0;
  });

const renderTimeRangeString = ({ startAt, endAt }) => `${startAt}–${endAt}`;

export const renderTimeRangesFor = restriction => {
  if (!restriction.timeRanges) return "All Day";

  const timeRanges = restriction.timeRanges.map(({ startAt, endAt }) => ({
    startAt: formatTime(inLocalTimeZoneWithoutConversion(startAt)),
    endAt: formatTime(inLocalTimeZoneWithoutConversion(endAt))
  }));

  return (
    " " + uniqueTimeRanges(timeRanges).map(renderTimeRangeString).join(", ")
  );
};

export const renderDays = restriction =>
  restriction.days.map(day => config.daysShort[day]).join(", ");

export const renderDates = restriction =>
  restriction.dates
    .map(date => formatDate(date, { inUsersTz: true }))
    .join(", ");

export const convertDaysIndicesToDays = (daysIndices = []) =>
  daysIndices.map(di => config.daysFull[di]);

export const convertTimeRangesToStrings = (timeRanges = []) =>
  timeRanges.map(tr => ({
    startAt: moment(tr.startAt).format("H:mm"),
    endAt: moment(tr.endAt).format("H:mm")
  }));

export const uniqueTimeRanges = (timeRanges = []) =>
  uniqWith(timeRanges, isEqual);
