export const processChange = event => {
  const input = event.target;

  if (!input) return event;

  const value = input.type === "checkbox" ? input.checked : input.value;

  return { [input.name]: value };
};

export const eventToState = ({ target }) => {
  switch (target.type) {
    case "checkbox":
      return { [target.name]: target.checked };
    case "radio":
      return { [target.name]: checkedRadioValueByName(target.name) };
    default:
      return { [target.name]: target.value };
  }
};

function checkedRadioValueByName(name) {
  const radios = document.querySelectorAll(`[name=${name}]`);
  for (const radio of radios) {
    if (radio.checked) {
      return radio.value === "on" ? void 0 : radio.value;
    }
  }
}
