import { inUsersTimezone } from "~brokerage/libs/helpers/TimeHelper";
import he from "he";
import { EMAIL_REGEX, PHONE_REGEX, URL_REGEX } from "./constants";

export const buildNames = names =>
  names.map(({ firstName, lastName }) => `${firstName} ${lastName}`);

const formatFeedbackMessage = message =>
  '<ol style=" margin: 0 0 -7px 0; padding: 11px 0 0 24px; ">' +
  message
    .split("\n")
    .filter(Boolean)
    .map(y => y.split(/ : (.*)/))
    .map(
      y => `
            <li style="margin-top: -26px"> <b>${y[0]}</b>
              <span>${y[1].trim()}</span>
            </li>
          `
    )
    .join("") +
  "<ol/>";

export const generateFeedbackText = (message, showingTeamAgentIds) => {
  const { msg, senderName, senderUserId, createdAt } = message;

  const isBehalfOf = !showingTeamAgentIds?.includes(senderUserId);
  const isBehalfOfExtra = isBehalfOf ? " (on behalf of showing agent)" : "";

  const standardPrefix = `${senderName} left Showing Feedback${isBehalfOfExtra} on ${inUsersTimezone(
    createdAt
  ).format("ddd, MMMM DD, [at] h:mm a")}`;

  let feedbackText = "";
  if (msg) {
    feedbackText = msg.includes(":")
      ? `${standardPrefix}\n\n${formatFeedbackMessage(msg)}`
      : msg;
  } else {
    feedbackText = `${standardPrefix}\n\nSurvey Data Not Found.`;
  }
  return feedbackText;
};

export const getHighlightedHtml = ({ message, searchWord }) =>
  !searchWord
    ? message
    : he
        .decode(message)
        .replace(new RegExp(`(${searchWord})`, "gi"), "<mark>$1</mark>");

const escapeHTML = str =>
  str.replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;");

export const linkifyMessage = (message = "") => {
  if (!message) return "";
  if (message.includes("<a ")) return message;

  return escapeHTML(message)
    .replace(
      URL_REGEX,
      url =>
        `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`
    )
    .replace(EMAIL_REGEX, email => `<a href="mailto:${email}">${email}</a>`)
    .replace(
      PHONE_REGEX,
      phone => `<a href="tel:${phone.replace(/[\s-]/g, "")}">${phone}</a>`
    );
};
