import React from "react";
import { connect } from "react-redux";
import css from "./Details.sass";
import MlsAlignedLogo from "~brokerage/assets/images/mls-aligned-logo";

import Link from "~brokerage/components/shared/Link";
import mlsSupportData from "./mlsSupportData";
import { SUPPORT_DETAILS } from "./constants";

const Details = ({ systemName = "" }) => {
  //Todo: create GET mls/[id]/support endpoint to return this data.
  const uniqueId = systemName.toLowerCase();
  const mlsData = mlsSupportData[uniqueId];

  return (
    <div className={css.container}>
      <div className={css.header} />
      <div className={css.content}>
        <div className={css.left}>
          <h1>Aligned Showings Support</h1>
        </div>
        {mlsData ? (
          <div className={css.right}>
            <h3>
              <small>
                Brought to you by <br />
              </small>
              {mlsData.name}
              <small> via</small> MLS Aligned
            </h3>
            <div className={css.logos}>
              <img src={`./img/mlsLogos/${uniqueId}.png`} />
              <img src={MlsAlignedLogo} />
            </div>
            <div className={css.contact}>
              {SUPPORT_DETAILS.map(
                ({ key, label, hrefType, icon: Icon }) =>
                  mlsData[key] && (
                    <div className={css.contactRow}>
                      <Icon size={18} color="#98acbb" />
                      <div className={css.contactDetail}>
                        <div>{label}</div>
                        <Link
                          href={`${hrefType ? `${hrefType}:` : ""}${
                            mlsData[key]
                          }`}
                          external
                        >
                          {mlsData[key]}
                        </Link>
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
        ) : (
          <div className={css.right}>
            <h3>Sorry, no support details were found.</h3>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ currentUser }) => {
  const { systemName } = currentUser;
  return {
    systemName
  };
};

export default connect(mapStateToProps)(Details);
