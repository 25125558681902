import React from "react";
import PropTypes from "prop-types";
import config from "~brokerage/config/config";
import TimeRange from "./TimeRange";
import { Row } from "~brokerage/components/shared/Form";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";
import css from "./RestrictionsAddForm.sass";
import { RiInformationLine } from "@remixicon/react";

const TimeRangeList = ({ timeRanges, onRangeChange, onAdd, onRemove }) => {
  const handleAddClick = () =>
    onAdd({
      startAt: `${config.defaultTimeRangeStartHour}:00`,
      endAt: `${config.defaultTimeRangeEndHour}:00`
    });

  return (
    <div style={{ marginTop: "10px" }}>
      <div className={css.info}>
        <RiInformationLine color="#999" className={css.infoIcon} size={15} />
        <span className={css.infoText}>
          The following time restrictions will be applied to each selected day.
        </span>
      </div>
      {timeRanges?.map((range, index) => (
        <TimeRange
          key={index}
          id={index}
          range={range}
          onRangeChange={onRangeChange}
          onRemove={onRemove}
        />
      ))}
      <Row offset="10">
        <Button variant="link" onClick={handleAddClick}>
          <Icon name="plus" />
          Add Another
        </Button>
      </Row>
    </div>
  );
};

TimeRangeList.propTypes = {
  timeRanges: PropTypes.array,
  onRangeChange: PropTypes.func,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func
};

export default TimeRangeList;
